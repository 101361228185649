<template>
  <v-card style="margin-bottom:5em">
      <v-toolbar>
        <v-toolbar-title>Schedule Change Admin</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="loadData()">
              <v-icon>fal fa-sync</v-icon>
            </v-btn>
          </template>
          <span>Refresh the data</span>
        </v-tooltip>
        <v-text-field ref="searchField" v-model="searchText" label="Search Name or Banner ID" clear-icon="fal fa-times" dense outlined hide-details clearable style="max-width:200px" @blur="searchBlur"></v-text-field>
        <!-- <v-tooltip v-if="!searchActive" top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="activateSearch">
              <v-icon>fal fa-search</v-icon>
            </v-btn>
          </template>
          <span>Search for a student by name or Banner ID</span>
        </v-tooltip> -->
        <v-select v-if="isRecords" v-model="filter" :items="filterOptions" label="Status Filter" dense hide-details outlined style="max-width:200px"></v-select>
        <v-select v-model="term" :items="terms" label="Term" dense hide-details outlined style="max-width:120px"></v-select>
      </v-toolbar>
    <v-card-text>This is where instructors and faculty advisors can view and acknowledge requested schedule changes.</v-card-text>
    <v-data-table :items="students" :headers="headers" :server-items-length="totalCount" :options="options" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 25] }" @update:options="updateOptions" @dblclick:row="(x, { item: { _id } }) => $router.push('/student/schedule-change/admin/' + _id)">
      <template v-slot:item.createdAt="{ item }">{{ stringFormatDate(item.createdAt) }}</template>
      <template v-slot:item.action="{ item }">
        <v-btn icon :to="'/student/schedule-change/admin/' + item._id">
          <v-icon>fal fa-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.status="{ item }">{{ getStatus(item) }}</template>
    </v-data-table>
  </v-card>
</template>
<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const isRecords = computed(() => 'Records' in roles.value || 'Technology Services' in roles.value)
    const students = ref([])
    const totalCount = ref(0)
    const headers = computed(() => {
      if (isRecords.value) {
        return [
          { text: 'Term', value: 'term' },
          { text: 'Banner ID', value: 'bannerId' },
          { text: 'Name', value: 'name' },
          { text: 'Submitted Date', value: 'submitDate' },
          { text: 'Status', value: 'status', sort: false },
          { text: 'View', value: 'action', sort: false }
        ]
      } else {
        return [
          { text: 'Term', value: 'term' },
          { text: 'Banner ID', value: 'bannerId' },
          { text: 'Name', value: 'name' },
          { text: 'Submitted Date', value: 'submitDate' },
          { text: 'View', value: 'action', sort: false }
        ]
      }
    })
    const options = computed(() => root.$store.state.student.scheduleAdmin.options || { page: 1, itemsPerPage: 10, sortBy: [], sortDesc: [] })

    function updateOptions (opt) {
      root.$store.commit('student/setScheduleAdminFields', { options: opt })
      loadData()
    }

    const terms = ref([])
    const term = computed({
      get: () => root.$store.state.student.scheduleAdmin.term,
      set: (term) => root.$store.commit('student/setScheduleAdminFields', { term })
    })

    onMounted(async () => {
      // Get possible terms from the term warehouse
      const { data } = await root.$feathers.service('student/schedule-change').find({ query: { $distinct: 'term' } })
      terms.value = data.map(({ _id }) => _id).sort().reverse()
      term.value = terms.value[0]
      if (searchText.value !== '' && searchText.value != null) searchActive.value = true
    })

    const searchActive = ref(false)
    const searchField = ref(null)
    const searchText = computed({
      get: () => root.$store.state.student.scheduleAdmin.searchText || '',
      set: (val) => root.$store.commit('student/setScheduleAdminFields', { searchText: val || '' })
    })

    function activateSearch () {
      searchActive.value = true
      root.$nextTick(() => searchField.value.focus())
    }

    function searchBlur () {
      if (searchText.value === '' || searchText.value == null) {
        searchActive.value = false
      }
    }

    const filter = computed({
      get: () => root.$store.state.student.scheduleAdmin.filter || 'all',
      set: (val) => root.$store.commit('student/setScheduleAdminFields', { filter: val })
    })
    const filterOptions = ref([
      { value: 'submitted', text: 'Pending initial Records review' },
      { value: 'pending', text: 'Pending instructor/advisor approval' },
      { value: 'approved', text: 'Waiting for Records to process' },
      { value: 'processed', text: 'Processed/completed submissions' },
      { value: 'returned', text: 'Returned to student' },
      { value: 'all', text: 'All Submissions' }
    ])

    watch([searchText, filter, term], () => {
      loadData()
    })

    async function loadData () {
      if (term.value === '') return
      let { page, itemsPerPage, sortBy, sortDesc } = options.value
      let query = {
        term: term.value,
        $limit: itemsPerPage,
        $skip: (itemsPerPage * (page - 1))
      }
      if (sortBy && sortBy.length > 0) {
        query.$sort = {}
        for (let i = 0; i < sortBy.length; i++) {
          query.$sort[sortBy[i]] = sortDesc[i] ? -1 : 1
        }
      }
      if (searchText.value !== '' && searchText.value != null) {
        if (searchText.value.substr(0, 1) === '@') {
          query.bannerId = { $regex: searchText.value }
        } else {
          query.name = { $regex: searchText.value, $options: 'i' }
        }
      }
      if ('Records' in roles.value || 'Technology Services' in roles.value) {
        if (filter.value !== 'all') query.status = filter.value
      } else {
        query.status = 'pending'
        query.$or = [
          { advisorApproval: { $elemMatch: { pidm: user.value.pidm, date: null } } },
          { changes: { $elemMatch: { 'approval.pidm': user.value.pidm, status: 'pending' } } }
        ]
      }
      let { total, data } = await root.$feathers.service('student/schedule-change').find({ query })
      totalCount.value = total
      students.value = data
    }

    function getStatus (entry) {
      switch (entry.status) {
        case 'submitted': return 'Initial Review'
        case 'returned': return 'Returned to Student'
        case 'pending': return 'Pending Approval'
        case 'approved': return 'Ready to be Processed'
        case 'processed': return 'Processed'
      }
    }

    return {
      user,
      roles,
      isRecords,
      students,
      headers,
      totalCount,
      options,
      updateOptions,
      terms,
      term,
      stringFormatDate,
      searchActive,
      searchField,
      searchText,
      activateSearch,
      searchBlur,
      filter,
      filterOptions,
      loadData,
      getStatus
    }
  }
}
</script>
